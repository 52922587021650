.App {
    background-color: #F2F3F5;
    color: #16151B;
    font-weight: 500;
    line-height: 1.1;
    transition: 0.5s;
    input  {
        outline: none;
    }
    button {
        font-weight: 400;
    }
    .headerTrigger {
        position: absolute;
        top: 5px;
        width: 40px;
        height: 40px;
        opacity: 0;
    }
}

[data-theme="dark"] {
    .App {
        background-color: #111016;
        color: #FFFFFF;
    }
}