*,
* ::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
}
li {
    list-style: none;
}
a {
    color: inherit;
}
a,
a:visited {
    text-decoration: none;
}
a:hover {
    text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: inherit;
    font-size: inherit;
}
img {
    vertical-align: top;
}

body,
html {
    height: 100%;
    line-height: 1;
    scroll-behavior: smooth;
}

body::-webkit-scrollbar {
    width: 12px;
}
body::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 3px;
}
body::-webkit-scrollbar-thumb {
    background: #a0a0a0;
    border-radius: 3px;
}
body::-webkit-scrollbar-thumb:hover {
    background: #888;
}